<div class="container--main d-none d-md-block" *ngIf="czyZaladowanoDane">
  <div class="ft__sort text-center pt-4">{{ szczegoly.nazwaProjektu }}</div>

  <div class="d-flex py-3">
    <div class="project__menu">
      <a class="active" routerLink="/project-details/{{ idProjektu }}"
        >Projekt</a
      >
      <a routerLink="/inventory/{{ idProjektu }}">Spis</a>
      <a routerLink="/locations/{{ idProjektu }}">Lokalizacje</a>
      <a routerLink="/client-data/{{ idProjektu }}">Ewidencja klienta</a>
      <a routerLink="/project-dictionaries/{{ idProjektu }}" *ngIf="czyAdmin()"
        >Słowniki</a
      >
      <a routerLink="/teams/{{ idProjektu }}" *ngIf="czyAdmin()">Zespoły</a>
      <a routerLink="/inventory-fields/{{ idProjektu }}" *ngIf="czyAdmin()"
        >Pola spisu</a
      >
    </div>
  </div>

  <div class="box__scroll">
    <div class="row">
      <div class="col-md-4 p-4">
        <div class="f-input mb-3">
          <input
            placeholder=""
            type="text"
            value="{{ szczegoly.nazwaProjektu }}"
            readonly
          />
        </div>
        <div class="f-input mb-3">
          <input
            placeholder=""
            type="text"
            value="{{ szczegoly.nazwaKlienta }}"
            readonly
          />
        </div>
        <div class="f-input mb-3">
          <input
            placeholder=""
            type="text"
            value="{{ szczegoly.email }}"
            readonly
          />
        </div>
        <div class="f-input mb-3">
          <input
            placeholder=""
            type="text"
            value="{{ ustalStatus() }}"
            readonly
          />
        </div>

        <div class="chb por mb-2">
          <input
            onclick="return false;"
            id="cb_1"
            type="checkbox"
            [(ngModel)]="szczegoly.czyWlaczycObslugeOswiadczen"
            name="czyWlaczycObslugeOswiadczen"
          />
          <label class="fs__chb" for="cb_1" readonly
            >Czy włączyć obsługę oświadczeń</label
          >
          <img
            *ngIf="szczegoly.czyWlaczycObslugeOswiadczen"
            src="assets/img/copy.png"
            style="
              width: 20px;
              height: 20px;
              align-self: start;
              cursor: pointer;
            "
            title="Skopiuj adres do wypełniania oświadczeń"
            (click)="pobierzAdresOswiadczen()"
          />
        </div>
        <div class="f-input mb-3" *ngIf="szczegoly.czyWlaczycObslugeOswiadczen">
          <input
            placeholder=""
            type="text"
            value="{{ szczegoly.domenyKlienta }}"
            readonly
          />
        </div>
        <div class="f-input mb-3" *ngIf="szczegoly.czyWlaczycObslugeOswiadczen">
          <input
            placeholder=""
            type="text"
            value="{{ szczegoly.dodatkowePolaNaDaneKlienta }}"
            readonly
          />
        </div>
        <div
          class="d-flex justify-content-between align-items-center fs__imported mb-2 mt-2"
          *ngIf="
            szczegoly.instrukcjaWypelnieniaOswiadczen &&
            szczegoly.czyWlaczycObslugeOswiadczen
          "
        >
          <div style="word-break: break-all">
            {{ szczegoly.instrukcjaWypelnieniaOswiadczen.nazwaPliku }}
          </div>
          <div>
            <button
              class="btn__download ml-2"
              (click)="pobierzInstrukcje()"
            ></button>
          </div>
        </div>
      </div>
      <div class="col-md-4 p-4 column__border">
        <label class="ft__07 por" for="">Koniec spisu do: </label>
        <div class="f-input mb-3 d-flex">
          <input
            placeholder=""
            type="text"
            value="{{ formatujDate(szczegoly.koniecSpisuDo) }}"
            readonly
          />
          <button class="btn__calender"></button>
        </div>

        <label class="ft__07 por" for="">Rozliczenie jednoznaczne do: </label>
        <div class="f-input mb-3 d-flex">
          <input
            placeholder=""
            type="text"
            value="{{ formatujDate(szczegoly.rozliczenieJednoznaczneDo) }}"
            readonly
          />
          <button class="btn__calender"></button>
        </div>

        <label class="ft__07 por" for="">Rozliczenie analityczne do: </label>
        <div class="f-input mb-3 d-flex">
          <input
            placeholder=""
            type="text"
            value="{{ formatujDate(szczegoly.rozliczenieAnalityczneDo) }}"
            readonly
          />
          <button class="btn__calender"></button>
        </div>

        <label class="ft__07 por" for="">Koniec projektu: </label>
        <div class="f-input mb-3 d-flex">
          <input
            placeholder=""
            type="text"
            value="{{ formatujDate(szczegoly.koniecProjektu) }}"
            readonly
          />
          <button class="btn__calender"></button>
        </div>
      </div>
      <div class="col-md-4 p-4">
        <textarea class="big__textarea" name="" id="" readonly
          >{{ szczegoly.notatka }} </textarea
        >
      </div>
    </div>
  </div>
</div>
<!-- mobile -->
<div class="container-fluid d-md-none" *ngIf="czyZaladowanoDane">
  <div class="pt-3 mt-1">
    <div class="ft__sort text-center">{{ szczegoly.nazwaProjektu }}</div>
  </div>

  <div class="d-fledx py-3 d-md-none">
    <div class="project__menu">
      <button class="btn__left"></button>
      <ngx-slick-carousel
        class="carousel menu_slider"
        #slickModal="slick-carousel"
        [config]="slideConfig"
      >
        <div ngxSlickItem class="slide">
          <a class="active" routerLink="/project-details/{{ idProjektu }}"
            >Projekt</a
          >
        </div>
        <div ngxSlickItem class="slide">
          <a routerLink="/inventory/{{ idProjektu }}">Spis</a>
        </div>
        <div ngxSlickItem class="slide">
          <a routerLink="/locations/{{ idProjektu }}">Lokalizacje</a>
        </div>
        <div ngxSlickItem class="slide">
          <a routerLink="/client-data/{{ idProjektu }}">Ewidencja klienta</a>
        </div>
        <div ngxSlickItem class="slide" *ngIf="czyAdmin()">
          <a routerLink="/project-dictionaries/{{ idProjektu }}">Słowniki</a>
        </div>
        <div ngxSlickItem class="slide" *ngIf="czyAdmin()">
          <a routerLink="/teams/{{ idProjektu }}">Zespoły</a>
        </div>
        <div ngxSlickItem class="slide" *ngIf="czyAdmin()">
          <a routerLink="/inventory-fields/{{ idProjektu }}">Pola spisu</a>
        </div>
      </ngx-slick-carousel>
      <button class="btn__right"></button>
    </div>
  </div>

  <div class="box__mobiled mb-4">
    <div class="row">
      <div class="col-md-4">
        <div class="f-input mb-3">
          <input
            placeholder=""
            type="text"
            value="{{ szczegoly.nazwaProjektu }}"
            readonly
          />
        </div>
        <div class="f-input mb-3">
          <input
            placeholder=""
            type="text"
            value="{{ szczegoly.nazwaKlienta }}"
            readonly
          />
        </div>
        <div class="f-input mb-3">
          <input
            placeholder=""
            type="text"
            value="{{ szczegoly.email }}"
            readonly
          />
        </div>
        <div class="f-input mb-3">
          <input
            placeholder=""
            type="text"
            value="{{ ustalStatus() }}"
            readonly
          />
        </div>
        <div class="chb por mb-2">
          <input
            onclick="return false;"
            id="cb_1"
            type="checkbox"
            [(ngModel)]="szczegoly.czyWlaczycObslugeOswiadczen"
            name="czyWlaczycObslugeOswiadczen"
          />
          <label class="fs__chb" for="cb_1" readonly
            >Czy włączyć obsługę oświadczeń</label
          >
          <img
            *ngIf="szczegoly.czyWlaczycObslugeOswiadczen"
            src="assets/img/copy.png"
            style="
              width: 20px;
              height: 20px;
              align-self: start;
              cursor: pointer;
            "
            title="Skopiuj adres do wypełniania oświadczeń"
            (click)="pobierzAdresOswiadczen()"
          />
        </div>
        <div class="f-input mb-3" *ngIf="szczegoly.czyWlaczycObslugeOswiadczen">
          <input
            placeholder=""
            type="text"
            value="{{ szczegoly.domenyKlienta }}"
            readonly
          />
        </div>
        <div class="f-input mb-3" *ngIf="szczegoly.czyWlaczycObslugeOswiadczen">
          <input
            placeholder=""
            type="text"
            value="{{ szczegoly.dodatkowePolaNaDaneKlienta }}"
            readonly
          />
        </div>
        <div
          class="d-flex justify-content-between align-items-center fs__imported mb-2 mt-2"
          *ngIf="
            szczegoly.instrukcjaWypelnieniaOswiadczen &&
            szczegoly.czyWlaczycObslugeOswiadczen
          "
        >
          <div style="word-break: break-all">
            {{ szczegoly.instrukcjaWypelnieniaOswiadczen.nazwaPliku }}
          </div>
          <div>
            <button
              class="btn__download ml-2"
              (click)="pobierzInstrukcje()"
            ></button>
          </div>
        </div>
      </div>
      <div class="col-md-4 column__border">
        <label class="ft__07 por" for="">Koniec spisu do: </label>
        <div class="f-input mb-3 d-flex">
          <input
            placeholder=""
            type="text"
            value="{{ formatujDate(szczegoly.koniecSpisuDo) }}"
            readonly
          />
          <button class="btn__calender"></button>
        </div>

        <label class="ft__07 por" for="">Rozliczenie jednoznaczne do: </label>
        <div class="f-input mb-3 d-flex">
          <input
            placeholder=""
            type="text"
            value="{{ formatujDate(szczegoly.rozliczenieJednoznaczneDo) }}"
            readonly
          />
          <button class="btn__calender"></button>
        </div>

        <label class="ft__07 por" for="">Rozliczenie analityczne do: </label>
        <div class="f-input mb-3 d-flex">
          <input
            placeholder=""
            type="text"
            value="{{ formatujDate(szczegoly.rozliczenieAnalityczneDo) }}"
            readonly
          />
          <button class="btn__calender"></button>
        </div>

        <label class="ft__07 por" for="">Koniec projektu: </label>
        <div class="f-input mb-3 d-flex">
          <input
            placeholder=""
            type="text"
            value="{{ formatujDate(szczegoly.koniecProjektu) }}"
            readonly
          />
          <button class="btn__calender"></button>
        </div>
      </div>
      <div class="col-md-4">
        <textarea class="big__textarea" name="" id="" readonly>{{
          szczegoly.notatka
        }}</textarea>
      </div>
    </div>
  </div>
</div>
<div class="bootom__buttons px-4" *ngIf="czyZaladowanoDane">
  <div
    class="container--mw800 flex-column flex-sm-row d-flex justify-content-between align-items-center"
  >
    <button class="btn__link order-1 order-sm-0" (click)="cofnij()">
      Cofnij
    </button>
    <button
      class="btn__link btn__link--red order-2 order-sm-0"
      *ngIf="czyPokazacPrzyciskUsunProjekt()"
      (click)="usunProjekt()"
    >
      Usuń projekt
    </button>
    <button
      class="btn__border btn__border--mini order-3 order-md-0"
      (click)="zrzucDane()"
      *ngIf="czyPokazacPrzyciskEksportuDanych()"
    >
      Eksport danych
    </button>
    <button
      class="btn__border btn__border--mini order-4 order-md-0"
      (click)="pobierzZrzuconeDane()"
      *ngIf="czyPokazacPrzyciskPobraniaEksportuDanych()"
    >
      Pobierz dane projektu
    </button>
    <button
      class="btn__blue order-0 order-sm-1"
      (click)="przejdzDoEdycji()"
      *ngIf="czyAdmin()"
    >
      Edytuj&nbsp;projekt
    </button>
  </div>
</div>
