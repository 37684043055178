import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { MenuService } from 'src/app/serwisy/ogolne/menu.service';
import { OswiadczeniaService } from 'src/app/serwisy/oswiadczenia/oswiadczenia.service';
import { BazowyComponent } from '../../bazowy.component';
import { Routing } from 'src/app/modele/wspolne/routing';
import { AutoryzujDoOswiadczeniaRezultat } from 'src/app/modele/oswiadczenia/autoryzujDoOswiadczeniaRezultat';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { Subscription } from 'rxjs';
import { PobierzSpisaneSrodkiLokalizacjiFiltry } from 'src/app/modele/projekty/spis/pobierzSpisaneSrodkiLokalizacjiFiltry';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { EdytujOswiadczenieModalComponent } from '../edytuj-oswiadczenie-modal/edytuj-oswiadczenie-modal.component';
import { PobierzSrodekRezultat } from 'src/app/modele/projekty/spis/pobierzSrodekRezultat';
import { EdytujSrodekModel } from 'src/app/modele/projekty/spis/edytujSrodekModel';
import { RodzajPolaSpisu } from 'src/app/modele/projekty/polaSpisu/rodzajPolaSpisu';
import { TakNieModalComponent } from '../../ogolne/tak-nie-modal/tak-nie-modal.component';
import { WyslijOswiadczenieModel } from 'src/app/modele/oswiadczenia/wyslijOswiadczenieModel';
import { OswiadczeniaSesjaService } from 'src/app/serwisy/oswiadczenia/oswiadczenia-sesja.service';

@Component({
  selector: 'app-oswiadczenia',
  templateUrl: './oswiadczenia.component.html',
  styleUrls: ['./oswiadczenia.component.css'],
})
export class OswiadczeniaComponent
  extends BazowyComponent
  implements OnInit, OnDestroy
{
  id: string;
  nazwaKlienta: string;
  czyMoznaDodawacOswiadczenia: boolean = false;
  uzytkownik: AutoryzujDoOswiadczeniaRezultat;
  czyZaladowanoDane: boolean;

  czyPokazacMenuSortowanie: boolean;
  wyszukiwanie: RejestrFiltrowanyRequest<PobierzSpisaneSrodkiLokalizacjiFiltry> =
    new RejestrFiltrowanyRequest<PobierzSpisaneSrodkiLokalizacjiFiltry>();
  spisaneSrodki: Array<Array<string>> = new Array();
  kolumnyTabeli: Array<string> = new Array();
  czyPierwszeUruchomienie: boolean = true;
  nazwaProjektu: string;
  adresLokalizacji: string;
  czyPokazanoWyszukiwarkeMobile: boolean;
  czyPokazanoPodmenu: boolean;
  kolumnaId: number = 0;

  pozostalyCzasSesji: number;

  subscription: Subscription | undefined;

  constructor(
    public authService: AuthService,
    public router: Router,
    private oswiadczeniaSerwis: OswiadczeniaService,
    modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public translate: TranslateService,
    public menuService: MenuService,
    private sesjaSerwis: OswiadczeniaSesjaService
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translate
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.menuService.schowajMenu();
      this.id = this.activatedRoute.snapshot.paramMap.get('id');
      this.inicjujJezyk();
      this.uzytkownik = JSON.parse(localStorage.getItem('uzytkownik'));
      if (!this.uzytkownik) {
        this.router.navigate([Routing.OswiadczeniaAutoryzacja, this.id]);
        return;
      }
      if (this.sesjaSerwis.sprawdzDateOstatniejAktywnosci()) {
        this.sesjaSerwis.wyczyscSesje();
        return;
      }

      this.subscription = this.sesjaSerwis.pozostalyCzas$.subscribe((time) => {
        this.pozostalyCzasSesji = time;
      });

      this.pobierzDaneDoEkranu();
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public pobierzDaneDoEkranu() {
    try {
      this.oswiadczeniaSerwis
        .pobierzDaneDoTabeliSrodkow()
        .subscribe((rezultat) => {
          if (this.czyRezultatOk(rezultat)) {
            this.nazwaKlienta = rezultat.element.nazwaKlienta;
            this.czyMoznaDodawacOswiadczenia = rezultat.element.czyWlaczone;
            this.czyZaladowanoDane = true;
            this.kolumnyTabeli = rezultat.element.kolumny;
            this.adresLokalizacji =
              this.uzytkownik.danePodstawowe.adresLokalizacji;

            this.pobierzSrodkiZPamieci();
            return;
          }

          this.wyswietlKomunikat(rezultat.wiadomosc, true);
        });
    } catch (blad) {}
  }

  pobierzInstrukcje() {
    this.oswiadczeniaSerwis.pobierzInstrukcje().subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        var a = document.createElement('a');
        a.target = '_blank';
        a.href = 'data: pdf;base64,' + rezultat.element.plik;
        a.download = 'Instrukcja.pdf';
        a.click();

        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  wyjdz() {
    this.modalReference = this.modalService.open(
      TakNieModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.pytanie =
      'Czy na pewno chcesz wyjść? Wszystkie dane zostaną utracone.';

    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.sesjaSerwis.wyczyscSesje();
        }
      },
      (onRejected) => {}
    );
  }

  dodaj() {
    this.modalReference = this.modalService.open(
      EdytujOswiadczenieModalComponent,
      new ModalOpcje(true, 'static', null, 'modal-dialog--fluid')
    );
    this.modalReference.componentInstance.idProjektu = this.id;
    this.modalReference.componentInstance.uzytkownik = this.uzytkownik;

    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.pobierzSrodkiZPamieci();
        }
      },
      (onRejected) => {}
    );
  }

  edytujSrodek(rekord: Array<string>) {
    var id = rekord[this.kolumnaId];
    this.modalReference = this.modalService.open(
      EdytujOswiadczenieModalComponent,
      new ModalOpcje(true, 'static', null, 'modal-dialog--fluid')
    );
    this.modalReference.componentInstance.idProjektu = this.id;
    this.modalReference.componentInstance.id = id;
    this.modalReference.componentInstance.uzytkownik = this.uzytkownik;

    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.pobierzSrodkiZPamieci();
        }
      },
      (onRejected) => {}
    );
  }

  pobierzSrodkiZPamieci() {
    this.spisaneSrodki = Array();
    var srodkiZPamieci: EdytujSrodekModel[] =
      JSON.parse(localStorage.getItem('spisaneSrodkiOswiadczenia')) || [];

    // srodkiZPamieci.forEach((m) => {
    //   var wartosci = [];
    //   wartosci.push(m.id);
    //   m.pola
    //     .filter((w) => w.poleSpisu.rodzajPolaSpisu != RodzajPolaSpisu.zdjecie)
    //     .forEach((w) => {
    //       wartosci.push(w.wartosc);
    //     });

    //   this.spisaneSrodki.push(wartosci);
    // });

    srodkiZPamieci.forEach((m) => {
      var wartosci = [];
      wartosci.push(m.id);
      this.kolumnyTabeli.forEach((kolumna) => {
        var pole = m.pola.find((m) => m.poleSpisu.nazwaPola == kolumna);
        wartosci.push(pole?.wartosc || '');
      });
      this.spisaneSrodki.push(wartosci);
    });

    return;
  }

  wyslij() {
    this.modalReference = this.modalService.open(
      TakNieModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.pytanie =
      'Czy na pewno chcesz wysłać oświadczenie? Edycja nie będzie już później możliwa.';

    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.wyslijOswiadczenieNaSerwer();
        }
      },
      (onRejected) => {}
    );
  }

  wyslijOswiadczenieNaSerwer() {
    var model = new WyslijOswiadczenieModel();
    model.srodki =
      (JSON.parse(
        localStorage.getItem('spisaneSrodkiOswiadczenia')
      ) as Array<EdytujSrodekModel>) || [];
    try {
      this.oswiadczeniaSerwis
        .wyslijOswiadczenie(model)
        .subscribe((rezultat) => {
          if (this.czyRezultatOk(rezultat)) {
            this.wyswietlKomunikat(
              'Oświadczenie zostało wysłane. Potwierdzenie otrzymasz na podany adres e-mail'
            );
            this.sesjaSerwis.wyczyscSesje();
            return;
          }

          this.wyswietlKomunikatZWlasnaKonfiguracja(
            rezultat.wiadomosc,
            {
              disableTimeOut: true,
            },
            true
          );
        });
    } catch (blad) {}
  }
}
