<div class="modal-content">
  <div class="modal-body">
    <button
      aria-label="Close"
      class="close"
      data-dismiss="modal"
      type="button"
      (click)="activeModal.dismiss()"
    ></button>
    <div class="ft__01 text-center py-2">Dodawanie pola spisu</div>
    <div class="text-center mb-2"></div>
    <form action="">
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('nazwaPola') ? 'error' : ''"
      >
        <input
          type="text"
          placeholder="Nazwa pola"
          name="nazwaPola"
          [(ngModel)]="model.nazwaPola"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('nazwaPola')"
        >
          {{ pobierzBladWalidacyjny("nazwaPola") }}
        </div>
      </div>

      <div
        class="input_container mb-3"
        [ngClass]="czyBladWalidacyjny('rodzajPolaSpisu') ? 'error' : ''"
      >
        <select
          [(ngModel)]="model.rodzajPolaSpisu"
          name="rodzajPolaSpisu"
          (change)="wybranoRodzajPola()"
        >
          <option [ngValue]="undefined" [disabled]="true">Rodzaj pola</option>
          <option [ngValue]="0">Checkbox</option>
          <option [ngValue]="1">Data</option>
          <option [ngValue]="2">Liczba całkowita</option>
          <option [ngValue]="3">Liczba zmiennoprzecinkowa</option>
          <option [ngValue]="4">Słownik jednokolumnowy</option>
          <option [ngValue]="5">Słownik wielokolumnowy</option>
          <option [ngValue]="6">Tekst</option>
          <option [ngValue]="7">Zdjęcie</option>
          <option [ngValue]="8">Inne</option>
          <option [ngValue]="9">Kod QR</option>
        </select>
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('rodzajPolaSpisu')"
        >
          {{ pobierzBladWalidacyjny("rodzajPolaSpisu") }}
        </div>
      </div>

      <div
        class="input_container mb-3"
        [ngClass]="czyBladWalidacyjny('slownikId') ? 'error' : ''"
        *ngIf="model.rodzajPolaSpisu == 4 || model.rodzajPolaSpisu == 5"
      >
        <select
          [(ngModel)]="model.slownikId"
          name="slownikId"
          (change)="wybranoSlownik()"
        >
          <option [ngValue]="undefined" [disabled]="true">Słownik</option>
          <option [ngValue]="slownik.id" *ngFor="let slownik of slowniki">
            {{ slownik.nazwa }}
          </option>
        </select>

        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('slownikId')"
        >
          {{ pobierzBladWalidacyjny("slownikId") }}
        </div>
      </div>

      <div
        class="input_container mb-3"
        [ngClass]="czyBladWalidacyjny('kolumnaSlownika') ? 'error' : ''"
        *ngIf="model.rodzajPolaSpisu == 5 && model.slownikId"
      >
        <select [(ngModel)]="model.kolumnaSlownika" name="kolumnaSlownika">
          <option [ngValue]="undefined" [disabled]="true">Kolumna</option>
          <option
            [ngValue]="kolumna.nazwa"
            *ngFor="let kolumna of kolumny; index as i"
          >
            {{ i + 1 }}. {{ kolumna.nazwa }}
          </option>
        </select>

        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('kolumnaSlownika')"
        >
          {{ pobierzBladWalidacyjny("kolumnaSlownika") }}
        </div>
      </div>

      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('wyrazenieRegularne') ? 'error' : ''"
        *ngIf="model.rodzajPolaSpisu == 8"
      >
        <input
          type="text"
          placeholder="Wyrażenie regularne"
          [(ngModel)]="model.wyrazenieRegularne"
          name="wyrazenieRegularne"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('wyrazenieRegularne')"
        >
          {{ pobierzBladWalidacyjny("wyrazenieRegularne") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('regexKomunikat') ? 'error' : ''"
        *ngIf="model.rodzajPolaSpisu == 8"
      >
        <input
          type="text"
          placeholder="Komunikat błędu"
          [(ngModel)]="model.regexKomunikat"
          name="regexKomunikat"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('regexKomunikat')"
        >
          {{ pobierzBladWalidacyjny("regexKomunikat") }}
        </div>
      </div>

      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('maksymalnaLiczbaZnakow') ? 'error' : ''"
        *ngIf="
          model.rodzajPolaSpisu != 0 &&
          model.rodzajPolaSpisu != 1 &&
          model.rodzajPolaSpisu != 7 &&
          model.rodzajPolaSpisu != 9
        "
      >
        <input
          type="number"
          placeholder="Maksymalna liczba znaków"
          [(ngModel)]="model.maksymalnaLiczbaZnakow"
          name="maksymalnaLiczbaZnakow"
          appTylkoPelneLiczby
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('maksymalnaLiczbaZnakow')"
        >
          {{ pobierzBladWalidacyjny("maksymalnaLiczbaZnakow") }}
        </div>
      </div>

      <div
        class="chb por mb-3"
        *ngIf="model.rodzajPolaSpisu != 7 && model.rodzajPolaSpisu != 9"
      >
        <input
          id="cb_01"
          type="checkbox"
          [(ngModel)]="model.tylkoDoOdczytu"
          name="tylkoDoOdczytu"
        />
        <label class="fs__chb" for="cb_01">Tylko do odczytu</label>
      </div>

      <!-- <div class="chb por mb-3" *ngIf="model.rodzajPolaSpisu != 0">
        <input
          id="cb_02"
          type="checkbox"
          [(ngModel)]="model.wymagalne"
          name="wymagalne"
        />
        <label class="fs__chb" for="cb_02">Wymagalne</label>
      </div> -->

      <div
        class="chb por mb-3"
        *ngIf="
          !(
            model.rodzajPolaSpisu == 1 ||
            model.rodzajPolaSpisu == 7 ||
            model.rodzajPolaSpisu == 0 ||
            model.rodzajPolaSpisu == 9
          )
        "
      >
        <input
          id="cb_03"
          type="checkbox"
          [(ngModel)]="model.skanowanieZablokowane"
          name="skanowanieZablokowane"
        />
        <label class="fs__chb" for="cb_03">Skanowanie zablokowane</label>
      </div>

      <div
        class="chb por mb-3"
        *ngIf="
          !(
            model.rodzajPolaSpisu == 1 ||
            model.rodzajPolaSpisu == 7 ||
            model.rodzajPolaSpisu == 0
          )
        "
      >
        <input
          id="cb_04"
          type="checkbox"
          [(ngModel)]="model.czyWartoscUnikalna"
          name="czyWartoscUnikalna"
        />
        <label class="fs__chb" for="cb_04">Czy wartość unikalna</label>
      </div>

      <div
        class="chb por mb-3"
        *ngIf="
          model.rodzajPolaSpisu != 7 &&
          model.rodzajPolaSpisu != 0 &&
          model.rodzajPolaSpisu != 9
        "
      >
        <input
          id="cb_05"
          type="checkbox"
          [(ngModel)]="model.czyUkrycWKolektorze"
          name="czyUkrycWKolektorze"
        />
        <label class="fs__chb" for="cb_05">Czy ukryć w kolektorze</label>
      </div>

      <div
        class="chb por mb-3"
        *ngIf="
          !(
            model.rodzajPolaSpisu == 1 ||
            model.rodzajPolaSpisu == 7 ||
            model.rodzajPolaSpisu == 0 ||
            model.rodzajPolaSpisu == 9
          )
        "
      >
        <input
          id="cb_06"
          type="checkbox"
          [(ngModel)]="model.czyUzupelnicAutomatycznieZEwidencji"
          name="czyUzupelnicAutomatycznieZEwidencji"
        />
        <label class="fs__chb" for="cb_06"
          >Czy uzupełniać automatycznie z ewidencji</label
        >
      </div>

      <div
        class="chb por mb-3"
        *ngIf="
          !(
            model.rodzajPolaSpisu == 1 ||
            model.rodzajPolaSpisu == 7 ||
            model.rodzajPolaSpisu == 0
          )
        "
      >
        <input
          id="cb_07"
          type="checkbox"
          [(ngModel)]="model.czySzukacWEwidencji"
          name="czySzukacWEwidencji"
        />
        <label class="fs__chb" for="cb_07">Czy szukać w ewidencji</label>
      </div>

      <div
        class="chb por mb-3"
        *ngIf="
          !(
            model.rodzajPolaSpisu == 1 ||
            model.rodzajPolaSpisu == 7 ||
            model.rodzajPolaSpisu == 0 ||
            model.rodzajPolaSpisu == 9
          ) && !model.czyDopisacZeraNaKoncu
        "
      >
        <input
          id="cb_08"
          type="checkbox"
          [(ngModel)]="model.czyDopisacZeraNaPoczatku"
          name="czyDopisacZeraNaPoczatku"
        />
        <label class="fs__chb" for="cb_08"
          >Czy uzupełnić wartość wiodącymi zerami</label
        >
      </div>

      <div
        class="chb por mb-3"
        *ngIf="
          !(
            model.rodzajPolaSpisu == 1 ||
            model.rodzajPolaSpisu == 7 ||
            model.rodzajPolaSpisu == 0 ||
            model.rodzajPolaSpisu == 9
          ) && !model.czyDopisacZeraNaPoczatku
        "
      >
        <input
          id="cb_09"
          type="checkbox"
          [(ngModel)]="model.czyDopisacZeraNaKoncu"
          name="czyDopisacZeraNaKoncu"
        />
        <label class="fs__chb" for="cb_09">Czy dopełnić wyrażenia zerami</label>
      </div>

      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('liczbaZerDoUzupelnienia') ? 'error' : ''"
        *ngIf="
          (model.czyDopisacZeraNaKoncu || model.czyDopisacZeraNaPoczatku) &&
          !(
            model.rodzajPolaSpisu == 1 ||
            model.rodzajPolaSpisu == 7 ||
            model.rodzajPolaSpisu == 0 ||
            model.rodzajPolaSpisu == 9
          )
        "
      >
        <input
          type="number"
          placeholder="Liczba znaków"
          [(ngModel)]="model.liczbaZerDoUzupelnienia"
          name="liczbaZerDoUzupelnienia"
          appTylkoPelneLiczby
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('liczbaZerDoUzupelnienia')"
        >
          {{ pobierzBladWalidacyjny("liczbaZerDoUzupelnienia") }}
        </div>
      </div>

      <div class="chb por mb-3" *ngIf="model.rodzajPolaSpisu != 7">
        <input
          id="cb_10"
          type="checkbox"
          [(ngModel)]="model.czyDomyslnieWRaporcie"
          name="czyDomyslnieWRaporcie"
        />
        <label class="fs__chb" for="cb_10">Czy domyślnie w raporcie</label>
      </div>
      <div
        class="chb por mb-3"
        *ngIf="model.rodzajPolaSpisu != 7 && model.rodzajPolaSpisu != 9"
      >
        <input
          id="cb_12"
          type="checkbox"
          [(ngModel)]="model.czyPoleMozeBycKopiowane"
          name="czyPoleMozeBycKopiowane"
        />
        <label class="fs__chb" for="cb_12"
          >Czy pole może być kopiowane z poprzedniego rekordu</label
        >
      </div>
      <div
        class="chb por mb-3"
        *ngIf="model.rodzajPolaSpisu != 7 && model.rodzajPolaSpisu != 0"
      >
        <input
          id="cb_11"
          type="checkbox"
          [(ngModel)]="model.czyParowanieReczne"
          name="czyParowanieReczne"
        />
        <label class="fs__chb" for="cb_11">Parowanie ręczne</label>
      </div>
      <div
        class="input_container mb-3"
        [ngClass]="
          czyBladWalidacyjny('rodzajSzukaniaWParowaniuRecznym') ? 'error' : ''
        "
        *ngIf="
          model.czyParowanieReczne &&
          model.rodzajPolaSpisu != 7 &&
          model.rodzajPolaSpisu != 0
        "
      >
        <select
          [(ngModel)]="model.rodzajSzukaniaWParowaniuRecznym"
          name="rodzajSzukaniaWParowaniuRecznym"
        >
          <option [ngValue]="undefined" [disabled]="true">
            Rodzaj szukania
          </option>
          <option [ngValue]="0">Odległość Levenshteina</option>
          <option [ngValue]="1">Tekst zawierający</option>
        </select>
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('rodzajSzukaniaWParowaniuRecznym')"
        >
          {{ pobierzBladWalidacyjny("rodzajSzukaniaWParowaniuRecznym") }}
        </div>
      </div>

      <div class="input_container mb-3" *ngIf="model.rodzajPolaSpisu != 0">
        <select
          [(ngModel)]="model.wymagalnosc"
          name="wymagalnosc"
          (change)="wybranoWymagalnosc()"
        >
          <option [ngValue]="undefined" [disabled]="true">Czy wymagane</option>
          <option [ngValue]="0">Niewymagane</option>
          <option [ngValue]="1">Wymagane</option>
          <option [ngValue]="2">
            Wymagane, jeżeli inne pole/pola jest/są puste
          </option>
          <option [ngValue]="3">
            Wymagane, jeżeli inne pole/pola zawiera/zawierają wartość ze
            słownika
          </option>
          <option [ngValue]="4">
            Wymagane, jeżeli inne pole/pola nie jest/są puste
          </option>
        </select>
      </div>

      <div
        class="input_container mb-3"
        *ngIf="model.rodzajPolaSpisu != 0 && model.wymagalnosc == 3"
        [ngClass]="czyBladWalidacyjny('wymagalnoscSlownikId') ? 'error' : ''"
      >
        <select
          [(ngModel)]="model.wymagalnoscSlownikId"
          name="wymagalnoscSlownikId"
        >
          <option [ngValue]="undefined" [disabled]="true">Słownik</option>
          <option
            [ngValue]="slownik.id"
            *ngFor="let slownik of slownikiWymagalnosci"
          >
            {{ slownik.nazwa }}
          </option>
        </select>
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('wymagalnoscSlownikId')"
        >
          {{ pobierzBladWalidacyjny("wymagalnoscSlownikId") }}
        </div>
      </div>

      <div
        class="input_container mb-3"
        *ngIf="
          model.rodzajPolaSpisu != 0 &&
          (model.wymagalnosc == 2 ||
            model.wymagalnosc == 3 ||
            model.wymagalnosc == 4)
        "
        [ngClass]="czyBladWalidacyjny('wymagalnoscPolaSpisuId') ? 'error' : ''"
      >
        <ng-select
          placeholder="Pola spisu"
          [searchable]="true"
          [clearable]="false"
          [multiple]="true"
          [items]="polaSpisuWymagalnosci"
          [(ngModel)]="model.wymagalnoscPolaSpisuId"
          bindLabel="nazwa"
          bindValue="id"
          [ngModelOptions]="{ standalone: true }"
          notFoundText="Brak pól"
          [closeOnSelect]="false"
          [clearSearchOnAdd]="true"
        >
        </ng-select>

        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('wymagalnoscPolaSpisuId')"
        >
          {{ pobierzBladWalidacyjny("wymagalnoscPolaSpisuId") }}
        </div>
      </div>

      <div
        class="input_container mb-3"
        *ngIf="
          model.rodzajPolaSpisu != 0 &&
          (model.wymagalnosc == 2 ||
            model.wymagalnosc == 3 ||
            model.wymagalnosc == 4) &&
          model.wymagalnoscPolaSpisuId.length > 1
        "
        [ngClass]="
          czyBladWalidacyjny('wymagalnoscPolaSpisuOperator') ? 'error' : ''
        "
      >
        <select
          [(ngModel)]="model.wymagalnoscPolaSpisuOperator"
          name="wymagalnoscPolaSpisuOperator"
        >
          <option [ngValue]="0" [disabled]="true">Operator</option>
          <option [ngValue]="1">I</option>
          <option [ngValue]="2">Lub</option>
        </select>

        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('wymagalnoscPolaSpisuOperator')"
        >
          {{ pobierzBladWalidacyjny("wymagalnoscPolaSpisuOperator") }}
        </div>
      </div>

      <div
        class="input_container mb-3"
        [ngClass]="
          czyBladWalidacyjny('widocznoscPolaNaOswiadczeniu') ? 'error' : ''
        "
      >
        <select
          [(ngModel)]="model.widocznoscPolaNaOswiadczeniu"
          name="widocznoscPolaNaOswiadczeniu"
        >
          <option [ngValue]="undefined" [disabled]="true">
            Widoczność na oświadczeniu
          </option>
          <option [ngValue]="0">Tylko w projekcie</option>
          <option [ngValue]="1">Tylko na oświadczeniu</option>
          <option [ngValue]="2" selected>Na oświadczeniu i w projekcie</option>
        </select>

        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('widocznoscPolaNaOswiadczeniu')"
        >
          {{ pobierzBladWalidacyjny("widocznoscPolaNaOswiadczeniu") }}
        </div>
      </div>

      <div class="chb por mb-3" *ngIf="model.rodzajPolaSpisu == 8">
        <input
          id="cb_20"
          type="checkbox"
          [(ngModel)]="model.czyMaskaWlaczonaNaOswiadczeniu"
          name="czyMaskaWlaczonaNaOswiadczeniu"
        />
        <label class="fs__chb" for="cb_20"
          >Czy maska włączona na oświadczeniu</label
        >
      </div>

      <div
        class="input_container pb-3 pt-2"
        *ngIf="model.rodzajPolaSpisu != 7 && model.rodzajPolaSpisu != 9"
      >
        <input
          type="text"
          placeholder="Domyślna wartość"
          [(ngModel)]="model.wartoscDomyslna"
          name="wartoscDomyslna"
        />
      </div>

      <div
        class="input_container mb-3"
        [ngClass]="czyBladWalidacyjny('separatorKoduQr') ? 'error' : ''"
        *ngIf="model.rodzajPolaSpisu == 9"
      >
        <ng-select
          [items]="separatoryKoduQr"
          bindLabel="name"
          bindValue="id"
          placeholder="Separator"
          appendTo="body"
          [multiple]="true"
          [(ngModel)]="model.separatorKoduQr"
          [ngModelOptions]="{ standalone: true }"
          [clearable]="false"
        >
        </ng-select>

        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('separatorKoduQr')"
        >
          {{ pobierzBladWalidacyjny("separatorKoduQr") }}
        </div>
      </div>

      <div
        class="chb por mb-3"
        *ngIf="
          model.rodzajPolaSpisu != 7 &&
          model.rodzajPolaSpisu != 9 &&
          model.rodzajPolaSpisu != 0
        "
      >
        <input
          id="cb_qr"
          type="checkbox"
          [(ngModel)]="model.przypiszZKoduQr"
          name="przypiszZKoduQr"
        />
        <label class="fs__chb" for="cb_qr">Przypisz z kodu QR</label>
      </div>

      <div class="input_container pb-3 mt-3">
        <button class="btn__primary" (click)="zapisz()">Zapisz</button>
      </div>
      <div class="input_container pb-3">
        <button class="btn__link" (click)="activeModal.dismiss()">
          Anuluj
        </button>
      </div>
    </form>
  </div>
</div>
