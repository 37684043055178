<div
  class="container--main d-none d-md-block"
  *ngIf="czyZaladowanoDane"
  [ngClass]="model.czyWlaczycObslugeOswiadczen ? 'oswiadczenia' : ''"
>
  <div class="ft__sort text-center pt-4">{{ model.nazwaProjektu }}</div>

  <div class="d-flex py-3">
    <div class="project__menu">
      <a class="active" routerLink="/project-details/{{ idProjektu }}"
        >Projekt</a
      >
      <a routerLink="/inventory/{{ idProjektu }}">Spis</a>
      <a routerLink="/locations/{{ idProjektu }}">Lokalizacje</a>
      <a routerLink="/client-data/{{ idProjektu }}">Ewidencja klienta</a>
      <a routerLink="/project-dictionaries/{{ idProjektu }}" *ngIf="czyAdmin()"
        >Słowniki</a
      >
      <a routerLink="/teams/{{ idProjektu }}" *ngIf="czyAdmin()">Zespoły</a>
      <a routerLink="/inventory-fields/{{ idProjektu }}" *ngIf="czyAdmin()"
        >Pola spisu</a
      >
    </div>
  </div>

  <div class="box__scroll">
    <div class="row">
      <div class="col-md-4 p-4">
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('nazwaProjektu') ? 'error' : ''"
        >
          <input
            type="text"
            placeholder="Nazwa projektu"
            name="nazwaProjektu"
            [(ngModel)]="model.nazwaProjektu"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('nazwaProjektu')"
          >
            {{ pobierzBladWalidacyjny("nazwaProjektu") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('nazwaKlienta') ? 'error' : ''"
        >
          <input
            type="text"
            placeholder="Nazwa klienta"
            name="nazwaKlienta"
            [(ngModel)]="model.nazwaKlienta"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('nazwaKlienta')"
          >
            {{ pobierzBladWalidacyjny("nazwaKlienta") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('email') ? 'error' : ''"
        >
          <input
            type="text"
            placeholder="E-mail"
            name="email"
            [(ngModel)]="model.email"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('email')"
          >
            {{ pobierzBladWalidacyjny("email") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('status') ? 'error' : ''"
        >
          <select name="" id="" [(ngModel)]="model.status" name="status">
            <option [ngValue]="undefined" [disabled]="true">Status</option>
            <option [ngValue]="'Przygotowanie'">Przygotowanie</option>
            <option [ngValue]="'Realizacja'">Realizacja</option>
            <option [ngValue]="'Zakonczony'">Zakończony</option>
            <option [ngValue]="'Zarchiwizowany'">Zarchiwizowany</option>
          </select>
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('status')"
          >
            {{ pobierzBladWalidacyjny("status") }}
          </div>
        </div>

        <div class="chb por mb-3">
          <input
            id="cb_1"
            type="checkbox"
            [(ngModel)]="model.czyWlaczycObslugeOswiadczen"
            name="czyWlaczycObslugeOswiadczen"
          />
          <label class="fs__chb" for="cb_1"
            >Czy włączyć obsługę oświadczeń</label
          >
          <img
            *ngIf="model.czyWlaczycObslugeOswiadczen"
            src="assets/img/copy.png"
            style="
              width: 20px;
              height: 20px;
              align-self: start;
              cursor: pointer;
            "
            title="Skopiuj adres do wypełniania oświadczeń"
            (click)="pobierzAdresOswiadczen()"
          />
        </div>
        <div
          *ngIf="model.czyWlaczycObslugeOswiadczen"
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('domenyKlienta') ? 'error' : ''"
        >
          <input
            type="text"
            placeholder="Domeny klienta"
            name="domenyKlienta"
            [(ngModel)]="model.domenyKlienta"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('domenyKlienta')"
          >
            {{ pobierzBladWalidacyjny("domenyKlienta") }}
          </div>
        </div>

        <div
          *ngIf="model.czyWlaczycObslugeOswiadczen"
          class="input_container pb-3"
          [ngClass]="
            czyBladWalidacyjny('dodatkowePolaNaDaneKlienta') ? 'error' : ''
          "
        >
          <input
            type="text"
            placeholder="Dodatkowe pola na dane pracownika"
            name="dodatkowePolaNaDaneKlienta"
            [(ngModel)]="model.dodatkowePolaNaDaneKlienta"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('dodatkowePolaNaDaneKlienta')"
          >
            {{ pobierzBladWalidacyjny("dodatkowePolaNaDaneKlienta") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          *ngIf="
            !model.instrukcjaWypelnieniaOswiadczen &&
            model.czyWlaczycObslugeOswiadczen
          "
        >
          <label
            class="btn__border pointer"
            for="file-upload"
            style="display: grid; align-items: center"
            >Wgraj instrukcję (format pdf)</label
          >
          <input
            id="file-upload"
            type="file"
            (change)="wybierzInstrukcjeZDysku($event)"
            hidden
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('instrukcjaWypelnieniaOswiadczen')"
          >
            {{ pobierzBladWalidacyjny("instrukcjaWypelnieniaOswiadczen") }}
          </div>
        </div>
        <div
          class="d-flex justify-content-between align-items-center fs__imported mb-2 mt-2"
          *ngIf="
            model.instrukcjaWypelnieniaOswiadczen &&
            model.czyWlaczycObslugeOswiadczen
          "
        >
          <div style="word-break: break-all">
            {{ model.instrukcjaWypelnieniaOswiadczen.nazwaPliku }}
          </div>
          <div>
            <button class="btn__delete" (click)="usunInstrukcje()"></button>
            <!-- <button class="btn__download ml-2"></button> -->
          </div>
        </div>
        <div
          class="error-info fs__error pt-2"
          *ngIf="
            model.instrukcjaWypelnieniaOswiadczen &&
            model.czyWlaczycObslugeOswiadczen &&
            czyBladWalidacyjny('instrukcjaWypelnieniaOswiadczen')
          "
        >
          {{ pobierzBladWalidacyjny("instrukcjaWypelnieniaOswiadczen") }}
        </div>
      </div>
      <div class="col-md-4 p-4 column__border">
        <label class="ft__07 por" for="">Koniec spisu do: </label>
        <div class="input_container d-flex align-items-center pb-3">
          <div class="input_container" style="width: 100%">
            <input
              type="text"
              placeholder="dd.mm.rrrr"
              readonly
              [value]="sformatowanaDataKoniecSpisuDo"
              class="pointer"
              (click)="wybierzDateKoniecSpisuDo()"
            />
            <button
              class="btn__input--clear"
              (click)="wyczyscDate('KoniecSpisuDo')"
              *ngIf="sformatowanaDataKoniecSpisuDo"
            ></button>
          </div>
          <button
            class="btn__calender"
            (click)="wybierzDateKoniecSpisuDo()"
          ></button>
        </div>

        <label for="">Rozliczenie jednoznaczne do: </label>
        <div class="input_container d-flex align-items-center pb-3">
          <div class="input_container" style="width: 100%">
            <input
              type="text"
              placeholder="dd.mm.rrrr"
              readonly
              [value]="sformatowanaDataRozliczenieJednoznaczneDo"
              class="pointer"
              (click)="wybierzDateRozliczenieJednoznaczneDo()"
            />
            <button
              class="btn__input--clear"
              (click)="wyczyscDate('RozliczenieJednoznaczneDo')"
              *ngIf="sformatowanaDataRozliczenieJednoznaczneDo"
            ></button>
          </div>
          <button
            class="btn__calender"
            (click)="wybierzDateRozliczenieJednoznaczneDo()"
          ></button>
        </div>

        <label for="">Rozliczenie analityczne do: </label>
        <div class="input_container d-flex align-items-center pb-3">
          <div class="input_container" style="width: 100%">
            <input
              type="text"
              placeholder="dd.mm.rrrr"
              readonly
              [value]="sformatowanaDataRozliczenieAnalityczneDo"
              class="pointer"
              (click)="wybierzDateRozliczenieAnalityczneDo()"
            />
            <button
              class="btn__input--clear"
              (click)="wyczyscDate('RozliczenieAnalityczneDo')"
              *ngIf="sformatowanaDataRozliczenieAnalityczneDo"
            ></button>
          </div>
          <button
            class="btn__calender"
            (click)="wybierzDateRozliczenieAnalityczneDo()"
          ></button>
        </div>

        <label for="">Koniec projektu: </label>
        <div class="input_container d-flex align-items-center pb-3">
          <div class="input_container" style="width: 100%">
            <input
              type="text"
              placeholder="dd.mm.rrrr"
              readonly
              [value]="sformatowanaDataKoniecProjektu"
              class="pointer"
              (click)="wybierzDateKoniecProjektu()"
            />
            <button
              class="btn__input--clear"
              (click)="wyczyscDate('KoniecProjektu')"
              *ngIf="sformatowanaDataKoniecProjektu"
            ></button>
          </div>
          <button
            class="btn__calender"
            (click)="wybierzDateKoniecProjektu()"
          ></button>
        </div>
      </div>
      <div class="col-md-4 p-4">
        <textarea
          class="big__textarea"
          [(ngModel)]="model.notatka"
          name="notatka"
          id=""
          placeholder="Notatka"
        ></textarea>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid d-md-none" *ngIf="czyZaladowanoDane">
  <div class="pt-3 mt-1">
    <div class="ft__sort text-center">{{ model.nazwaProjektu }}</div>
  </div>

  <div class="d-fledx py-3 d-md-none">
    <div class="project__menu">
      <button class="btn__left"></button>
      <ngx-slick-carousel
        class="carousel menu_slider"
        #slickModal="slick-carousel"
        [config]="slideConfig"
      >
        <div ngxSlickItem class="slide">
          <a class="active" routerLink="/project-details/{{ idProjektu }}"
            >Projekt</a
          >
        </div>
        <div ngxSlickItem class="slide">
          <a routerLink="/inventory/{{ idProjektu }}">Spis</a>
        </div>
        <div ngxSlickItem class="slide">
          <a routerLink="/locations/{{ idProjektu }}">Lokalizacje</a>
        </div>
        <div ngxSlickItem class="slide">
          <a routerLink="/client-data/{{ idProjektu }}">Ewidencja klienta</a>
        </div>
        <div ngxSlickItem class="slide" *ngIf="czyAdmin()">
          <a routerLink="/project-dictionaries/{{ idProjektu }}">Słowniki</a>
        </div>
        <div ngxSlickItem class="slide" *ngIf="czyAdmin()">
          <a routerLink="/teams/{{ idProjektu }}">Zespoły</a>
        </div>
        <div ngxSlickItem class="slide" *ngIf="czyAdmin()">
          <a routerLink="/inventory-fields/{{ idProjektu }}">Pola spisu</a>
        </div>
      </ngx-slick-carousel>
      <button class="btn__right"></button>
    </div>
  </div>

  <div class="box__mobiled mb-4">
    <div class="row">
      <div class="col-md-4">
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('nazwaProjektu') ? 'error' : ''"
        >
          <input
            type="text"
            placeholder="Nazwa projektu"
            name="nazwaProjektu"
            [(ngModel)]="model.nazwaProjektu"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('nazwaProjektu')"
          >
            {{ pobierzBladWalidacyjny("nazwaProjektu") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('nazwaKlienta') ? 'error' : ''"
        >
          <input
            type="text"
            placeholder="Nazwa klienta"
            name="nazwaKlienta"
            [(ngModel)]="model.nazwaKlienta"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('nazwaKlienta')"
          >
            {{ pobierzBladWalidacyjny("nazwaKlienta") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('email') ? 'error' : ''"
        >
          <input
            type="text"
            placeholder="E-mail"
            name="email"
            [(ngModel)]="model.email"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('email')"
          >
            {{ pobierzBladWalidacyjny("email") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('status') ? 'error' : ''"
        >
          <select name="" id="" [(ngModel)]="model.status" name="status">
            <option [ngValue]="undefined" [disabled]="true">Status</option>
            <option [ngValue]="'Przygotowanie'">Przygotowanie</option>
            <option [ngValue]="'Realizacja'">Realizacja</option>
            <option [ngValue]="'Zakonczony'">Zakończony</option>
            <option [ngValue]="'Zarchiwizowany'">Zarchiwizowany</option>
          </select>
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('status')"
          >
            {{ pobierzBladWalidacyjny("status") }}
          </div>
        </div>

        <div class="chb por mb-3">
          <input
            id="cb_1"
            type="checkbox"
            [(ngModel)]="model.czyWlaczycObslugeOswiadczen"
            name="czyWlaczycObslugeOswiadczen"
          />
          <label class="fs__chb" for="cb_1"
            >Czy włączyć obsługę oświadczeń</label
          >
          <img
            *ngIf="model.czyWlaczycObslugeOswiadczen"
            src="assets/img/copy.png"
            style="
              width: 20px;
              height: 20px;
              align-self: start;
              cursor: pointer;
            "
            title="Skopiuj adres do wypełniania oświadczeń"
            (click)="pobierzAdresOswiadczen()"
          />
        </div>
        <div
          *ngIf="model.czyWlaczycObslugeOswiadczen"
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('domenyKlienta') ? 'error' : ''"
        >
          <input
            type="text"
            placeholder="Domeny klienta"
            name="domenyKlienta"
            [(ngModel)]="model.domenyKlienta"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('domenyKlienta')"
          >
            {{ pobierzBladWalidacyjny("domenyKlienta") }}
          </div>
        </div>

        <div
          *ngIf="model.czyWlaczycObslugeOswiadczen"
          class="input_container pb-3"
          [ngClass]="
            czyBladWalidacyjny('dodatkowePolaNaDaneKlienta') ? 'error' : ''
          "
        >
          <input
            type="text"
            placeholder="Dodatkowe pola na dane pracownika"
            name="dodatkowePolaNaDaneKlienta"
            [(ngModel)]="model.dodatkowePolaNaDaneKlienta"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('dodatkowePolaNaDaneKlienta')"
          >
            {{ pobierzBladWalidacyjny("dodatkowePolaNaDaneKlienta") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          *ngIf="
            !model.instrukcjaWypelnieniaOswiadczen &&
            model.czyWlaczycObslugeOswiadczen
          "
        >
          <label
            class="btn__border pointer"
            for="file-upload"
            style="display: grid; align-items: center"
            >Wgraj instrukcję (format pdf)</label
          >
          <input
            id="file-upload"
            type="file"
            (change)="wybierzInstrukcjeZDysku($event)"
            hidden
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('instrukcjaWypelnieniaOswiadczen')"
          >
            {{ pobierzBladWalidacyjny("instrukcjaWypelnieniaOswiadczen") }}
          </div>
        </div>
        <div
          class="d-flex justify-content-between align-items-center fs__imported mb-2 mt-2"
          *ngIf="
            model.instrukcjaWypelnieniaOswiadczen &&
            model.czyWlaczycObslugeOswiadczen
          "
        >
          <div style="word-break: break-all">
            {{ model.instrukcjaWypelnieniaOswiadczen.nazwaPliku }}
          </div>
          <div>
            <button class="btn__delete" (click)="usunInstrukcje()"></button>
            <!-- <button class="btn__download ml-2"></button> -->
          </div>
        </div>
        <div
          class="error-info fs__error pt-2"
          *ngIf="
            model.instrukcjaWypelnieniaOswiadczen &&
            model.czyWlaczycObslugeOswiadczen &&
            czyBladWalidacyjny('instrukcjaWypelnieniaOswiadczen')
          "
        >
          {{ pobierzBladWalidacyjny("instrukcjaWypelnieniaOswiadczen") }}
        </div>
      </div>
      <div class="col-md-4 column__border">
        <label class="ft__07 por" for="">Koniec spisu do: </label>
        <div class="input_container d-flex align-items-center pb-3">
          <div class="input_container" style="width: 100%">
            <input
              type="text"
              placeholder="dd.mm.rrrr"
              readonly
              [value]="sformatowanaDataKoniecSpisuDo"
              class="pointer"
              (click)="wybierzDateKoniecSpisuDo()"
            />
            <button
              class="btn__input--clear"
              (click)="wyczyscDate('KoniecSpisuDo')"
              *ngIf="sformatowanaDataKoniecSpisuDo"
            ></button>
          </div>
          <button
            class="btn__calender"
            (click)="wybierzDateKoniecSpisuDo()"
          ></button>
        </div>

        <label for="">Rozliczenie jednoznaczne do: </label>
        <div class="input_container d-flex align-items-center pb-3">
          <div class="input_container" style="width: 100%">
            <input
              type="text"
              placeholder="dd.mm.rrrr"
              readonly
              [value]="sformatowanaDataRozliczenieJednoznaczneDo"
              class="pointer"
              (click)="wybierzDateRozliczenieJednoznaczneDo()"
            />
            <button
              class="btn__input--clear"
              (click)="wyczyscDate('RozliczenieJednoznaczneDo')"
              *ngIf="sformatowanaDataRozliczenieJednoznaczneDo"
            ></button>
          </div>
          <button
            class="btn__calender"
            (click)="wybierzDateRozliczenieJednoznaczneDo()"
          ></button>
        </div>

        <label for="">Rozliczenie analityczne do: </label>
        <div class="input_container d-flex align-items-center pb-3">
          <div class="input_container" style="width: 100%">
            <input
              type="text"
              placeholder="dd.mm.rrrr"
              readonly
              [value]="sformatowanaDataRozliczenieAnalityczneDo"
              class="pointer"
              (click)="wybierzDateRozliczenieAnalityczneDo()"
            />
            <button
              class="btn__input--clear"
              (click)="wyczyscDate('RozliczenieAnalityczneDo')"
              *ngIf="sformatowanaDataRozliczenieAnalityczneDo"
            ></button>
          </div>
          <button
            class="btn__calender"
            (click)="wybierzDateRozliczenieAnalityczneDo()"
          ></button>
        </div>

        <label for="">Koniec projektu: </label>
        <div class="input_container d-flex align-items-center pb-3">
          <div class="input_container" style="width: 100%">
            <input
              type="text"
              placeholder="dd.mm.rrrr"
              readonly
              [value]="sformatowanaDataKoniecProjektu"
              class="pointer"
              (click)="wybierzDateKoniecProjektu()"
            />
            <button
              class="btn__input--clear"
              (click)="wyczyscDate('KoniecProjektu')"
              *ngIf="sformatowanaDataKoniecProjektu"
            ></button>
          </div>
          <button
            class="btn__calender"
            (click)="wybierzDateKoniecProjektu()"
          ></button>
        </div>
      </div>
      <div class="col-md-4">
        <textarea
          class="big__textarea"
          [(ngModel)]="model.notatka"
          name="notatka"
          id=""
          placeholder="Notatka"
        ></textarea>
      </div>
    </div>
  </div>
</div>
<div class="bootom__buttons px-4" *ngIf="czyZaladowanoDane">
  <div
    class="container--mw600 flex-column flex-sm-row d-flex justify-content-between align-items-center"
  >
    <button class="btn__link order-1 order-sm-0" (click)="anuluj()">
      Anuluj
    </button>
    <button class="btn__blue order-0 order-sm-1" (click)="zapisz()">
      Zapisz&nbsp;projekt
    </button>
  </div>
</div>
